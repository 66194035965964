<template>
    <div class="search">
        <div v-if="isLoading" class="loading-info">
            <Processing message="商品資料載入中" />
        </div>
        <form
            ref="form"
            class="filter"
            :id="`list-${uuid}`"
            :class="{ disabled: isLoading }"
        >
            <div
                class="filter-option"
                :class="{
                    'margin-bottom-20':
                        formValid ||
                        matchOnSaleDate !== '' ||
                        ageOptions.length > 0
                }"
            >
                <Dropdown
                    :required="true"
                    :options="suppliers"
                    v-model="option.company"
                    :searchable="true"
                    title="保險公司"
                    placeholder="保險公司"
                    :width="120"
                    :disabled="isLoading"
                />
                <Dropdown
                    :required="true"
                    :options="productNameOptions"
                    :searchable="true"
                    v-model="option.productName"
                    title="商品名稱"
                    placeholder="請輸入商品名稱"
                    :width="300"
                    :disabled="isLoading"
                />
                <Textbox
                    :required="true"
                    type="date"
                    v-model="option.dates"
                    title="上架日"
                    :class="{
                        'has-sales': matchOnSaleDate,
                        'no-sales': matchOnSaleDate === null
                    }"
                    :sale-tip="`上架日：${matchOnSaleDate}`"
                    :disabled="isLoading"
                />
                <Textbox
                    :required="true"
                    type="number"
                    v-model="option.price"
                    maxlength="12"
                    title="保費(NTD)"
                    placeholder="請輸入保費(NTD)"
                    :width="150"
                    class="tip"
                    :class="{ 'fyp-tip': option.price }"
                    :disabled="isLoading"
                />
                <Dropdown
                    :required="true"
                    :searchable="true"
                    :options="yearOptions"
                    :disabled="yearOptions.length === 0"
                    v-model="option.expiration"
                    title="年期"
                    :placeholder="isLoadingYear ? '載入中...' : '年期'"
                    :width="110"
                />
                <Textbox
                    class="ins-age tip"
                    :class="{
                        'age-tip': option.age,
                        'error-state': !insAgeValid
                    }"
                    :required="true"
                    v-model="option.age"
                    maxlength="3"
                    type="number"
                    title="投保年齡"
                    :placeholder="isLoadingAge ? '載入中...' : '投保年齡'"
                    :disabled="ageOptions.length === 0"
                    :age-tips="insAgeHint"
                    :width="95"
                />
            </div>
            <div v-if="!formValid" class="error_style">*請完成未填寫欄位</div>
            <div class="filter-result">
                <div
                    class="first-year-commission-icon"
                    :class="{ disqualified: !productInfo.hasFyb }"
                ></div>
                <div
                    class="extend-year-commission-icon"
                    :class="{ disqualified: !productInfo.hasRyb }"
                ></div>
                <div
                    class="thirteen-persistency-icon"
                    :class="{
                        disqualified: !productInfo.has13Persistency
                    }"
                ></div>
                <div
                    class="twenty-five-persistency-icon"
                    :class="{
                        disqualified: !productInfo.has25Persistency
                    }"
                ></div>
                <div
                    class="thirty-seven-persistency-icon"
                    :class="{
                        disqualified: !productInfo.has37Persistency
                    }"
                ></div>
                <div
                    class="service-allowance-icon"
                    :class="{ disqualified: !productInfo.hasSab }"
                ></div>
                <div
                    class="year-end-bonus-icon"
                    :class="{ disqualified: !productInfo.hasYeb }"
                ></div>
            </div>
        </form>
        <div class="control">
            <div @click="addList()" class="add-icon" />
            <div
                @click="copyList()"
                class="copy-icon"
                :class="{ disabled: isLoading }"
            />
            <div
                @click="removeList()"
                class="product-remove-icon"
                :class="{
                    disabled: modelValue.length <= 1 || isLoading
                }"
            />
        </div>
    </div>
</template>
<script>
import Dropdown from '@/components/Dropdown.vue'
import Textbox from '@/components/Textbox.vue'
import Processing from '@/components/Processing.vue'
import _ from 'lodash'
import {
    postProductListAPI,
    postProductTimeInfoAPI,
    postProductInfoAPI
} from '@/assets/javascripts/api'
export default {
    name: 'ProductFilterCard',
    components: { Dropdown, Textbox, Processing },
    emits: ['add-list', 'copy-list', 'remove-list', 'update:modelValue'],
    props: {
        suppliers: {
            type: Array,
            default: function () {
                return []
            }
        },
        uuid: {
            type: String,
            default: ''
        },
        index: {
            type: Number,
            default: 0
        },
        modelValue: {
            type: Object,
            default: function () {
                return {}
            }
        }
    },
    methods: {
        async getProductAPI(payload, permission, api) {
            this.isLoading = true
            try {
                const res = await this.$getResponse(payload, permission, api)
                return res
            } catch (e) {
                console.error(e)
            } finally {
                this.isLoading = false
            }
        },
        async getProductName(supplierCode) {
            if (!supplierCode) return (this.productNameOptions = [])
            const payload = { supplierCode }
            const res = await this.getProductAPI(
                payload,
                'skip',
                postProductListAPI
            )
            this.productNameOptions = res.data.products.map((data) => {
                return {
                    label: data.name,
                    value: data.name
                }
            })
        },
        async getProductTimeInfo(supplierCode, productName, onSaleDate) {
            this.option.expiration = ''
            this.yearOptions = []
            if (!productName || !onSaleDate) {
                this.matchOnSaleDate = ''
                return
            }
            this.isLoadingYear = true
            const payload = { supplierCode, productName, onSaleDate }
            const res = await this.getProductAPI(
                payload,
                'skip',
                postProductTimeInfoAPI
            )
            if (!res.data.productId) {
                this.matchOnSaleDate = null
                this.isLoadingYear = false
                return
            }
            const yPeriodOptions = _.sortBy(res.data.yPeriodOptions, (item) =>
                parseInt(item)
            )
            this.yearOptions = yPeriodOptions.map((data) => {
                return {
                    label: `${data} 年期`,
                    value: data
                }
            })
            this.productId = res.data.productId
            this.matchOnSaleDate = res.data.matchOnSaleDate
            this.isLoadingYear = false
        },
        async getProductInfo(productId, yPeriod) {
            if (!productId || !yPeriod) {
                this.productInfo = {}
                this.ageOptions = []
                return
            }
            this.isLoadingAge = true
            const payload = { productId, yPeriod }
            const res = await this.getProductAPI(
                payload,
                'skip',
                postProductInfoAPI
            )
            this.productInfo = res.data
            this.ageOptions = res.data.insAgeOptions
            if (res.data.insAgeOptions.length === 0) this.option.age = ''
            this.isLoadingAge = false
        },
        getApiData() {
            return {
                option: this.option,
                productNameOptions: this.productNameOptions,
                yearOptions: this.yearOptions,
                productInfo: this.productInfo,
                ageOptions: this.ageOptions,
                matchOnSaleDate: this.matchOnSaleDate,
                productId: this.productId
            }
        },
        addList() {
            if (!this.isLoading) this.checkFormValid()
            this.$emit('add-list', this.index)
        },
        copyList() {
            if (this.isLoading) return
            this.checkFormValid()
            const data = _.cloneDeep(this.getApiData())
            this.$emit('copy-list', this.index, data)
        },
        removeList() {
            if (this.isLoading) return
            this.$emit('remove-list', this.uuid)
        },
        checkFormValid() {
            const form = document.querySelector(`#list-${this.uuid}`)
            const input = form.querySelectorAll(
                'input:not(.hide):not(.flatpickr-input)'
            )
            let valid = true
            input.forEach((i) => {
                if (!i.value) {
                    valid = false
                    i.parentNode.classList.add('input-error-ci-style')
                }
            })
            let ageValid = true
            if (
                !this.isWithinRange(this.option.age, this.ageOptions) &&
                this.ageOptions.length > 0
            ) {
                ageValid = false
            }
            this.insAgeValid = ageValid
            this.formValid = valid
            return valid && ageValid
        },
        isWithinRange(num, range) {
            const min = Math.min(...range)
            const max = Math.max(...range)
            return min <= num && max >= num
        }
    },
    computed: {
        insAgeHint() {
            if (this.ageOptions.length === 0) return ''
            const max = Math.max(...this.ageOptions)
            const min = Math.min(...this.ageOptions)
            return `請輸入${min}~${max}歲`
        }
    },
    watch: {
        modelValue: {
            deep: true,
            immediate: true,
            handler() {
                let hasDefault = false
                const newList = this.modelValue.map((data) => {
                    if (data.uuid === this.uuid) {
                        this.option = data.options
                        if (data.defaultData) {
                            let {
                                ageOptions,
                                matchOnSaleDate,
                                option,
                                productId,
                                productInfo,
                                productNameOptions,
                                yearOptions
                            } = _.cloneDeep(data.defaultData)
                            this.ageOptions = ageOptions
                            this.matchOnSaleDate = matchOnSaleDate
                            this.productId = productId
                            this.productInfo = productInfo
                            this.productNameOptions = productNameOptions
                            this.yearOptions = yearOptions
                            this.defaultOption = option
                            hasDefault = true
                            delete data.defaultData
                        }
                    }
                    return data
                })
                if (hasDefault) {
                    this.initialing = true
                    this.$emit('update:modelValue', newList)
                }
            }
        },
        option: {
            handler(newVal) {
                if (!this.formValid) {
                    let validity = true
                    for (let key in newVal) {
                        if (!newVal[key]) validity = false
                    }
                    if (validity) this.formValid = validity
                }
                const list = this.modelValue.map((data) => {
                    if (data.uuid === this.uuid) {
                        return {
                            ...data,
                            options: newVal,
                            productId: this.productId
                        }
                    }
                    return data
                })
                this.$emit('update:modelValue', list)
            },
            deep: true
        },
        'option.company': {
            handler(newVal) {
                if (this.initialing) return
                this.getProductName(newVal)
            }
        },
        'option.productName': {
            handler(newVal) {
                if (this.initialing) return
                this.getProductTimeInfo(
                    this.option.company,
                    newVal,
                    this.option.dates
                )
            }
        },
        'option.dates': {
            handler(newVal) {
                if (this.initialing) return
                this.getProductTimeInfo(
                    this.option.company,
                    this.option.productName,
                    newVal
                )
            }
        },
        'option.expiration': {
            handler(newVal) {
                if (this.initialing) return
                if (!newVal) {
                    this.option.age = ''
                    this.ageOptions = []
                }
                this.getProductInfo(this.productId, newVal)
            }
        },
        'option.age': {
            handler(newVal) {
                if (!newVal) return
                if (this.isWithinRange(newVal, this.ageOptions)) {
                    this.insAgeValid = true
                }
            }
        },
        defaultOption: {
            deep: true,
            immediate: true,
            handler() {
                if (!this.initialing) return
                Object.keys(this.defaultOption).forEach((key) => {
                    if (this.defaultOption[key]) {
                        this.option[key] = this.defaultOption[key]
                    }
                })
                this.initialing = false
            }
        }
    },
    data() {
        return {
            isLoading: false,
            isLoadingYear: false,
            isLoadingAge: false,
            productNameOptions: [],
            yearOptions: [],
            productInfo: {},
            ageOptions: [],
            matchOnSaleDate: '',
            productId: '',
            option: {},
            formValid: true,
            defaultOption: {},
            initialing: false,
            insAgeValid: true
        }
    }
}
</script>
<style lang="scss" scoped>
.disqualified {
    opacity: 0.4;
}

.search {
    position: relative;
    display: flex;
    @media screen and (max-width: 576px) {
        width: auto;
        overflow: scroll;
    }

    .loading-info {
        position: absolute;
        top: calc(50% - 10px);
        left: calc(50% - 32px);
        transform: translate(-50%, -50%);
        z-index: 3;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $sixth-black;
        user-select: none;
        :deep(.process-loading-container) {
            .process-loading-icon {
                height: 14px;
                width: 14px;
                margin-bottom: 5px;
                border-width: 3px;
            }
            .message {
                line-height: 15px;
            }
        }
    }
}

.filter {
    display: flex;
    flex-direction: column;
    background-color: $primary-white;
    padding: 15px;
    width: 100%;
    margin-bottom: 10px;
    position: relative;
    border: 1px solid #00adee;
    border-radius: 3px;

    &-option {
        display: flex;
        flex-wrap: wrap;
        div:nth-child(1) {
            max-width: 120px;
        }
        div:nth-child(2) {
            max-width: 400px;
            width: 300px;
        }
        div:nth-child(3) {
            max-width: 150px;
        }
        div:nth-child(5) {
            max-width: 110px;
        }
        div:nth-child(6) {
            max-width: 95px;
        }
        & > div {
            margin-right: 5px;
        }
    }
    &-result {
        display: flex;
        padding-top: 10px;
        border-top: 1px solid $sum-row-grey;

        > div {
            width: 20px;
            height: 20px;
            margin-left: 5px;
        }
    }
    .error_style {
        color: #ee2900;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        margin-bottom: 10px;
    }
    .has-sales {
        position: relative;
        color: $placeholder-black;

        &::after {
            content: attr(sale-tip);
            display: block !important;
            position: absolute;
            width: 100%;
            font-size: 10px;
            top: 95%;
            right: 0%;
        }
    }
    .no-sales {
        position: relative;
        color: $placeholder-black;

        &::after {
            content: '此時間尚未銷售';
            display: block !important;
            position: absolute;
            width: 100%;
            font-size: 10px;
            top: 95%;
            right: 0%;
        }
    }

    @media screen and (max-width: 576px) {
        width: auto;
        &-option {
            flex-wrap: nowrap;
        }
    }
}

.control {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
    & > div {
        cursor: pointer;
        width: 24px;
        height: 24px;
        margin-bottom: 16px;
    }
}
// 客製化元件樣式
:deep(.textbox-container .select) {
    background-color: $primary-white;
    border: 1px solid $sum-row-grey;
}
:deep(.input-container) {
    margin-bottom: 0;
    overflow: visible;

    .option-container {
        z-index: 2;
    }

    &.input-disabled {
        input {
            opacity: 0.4;
            cursor: not-allowed;
        }
    }

    &.tip {
        position: relative;
        font-size: 14px;
        &:before {
            margin-top: 16px;
            position: absolute;
            top: 0px;
            right: 8px;
            line-height: 40px;
            z-index: 1;
        }
        input {
            padding-right: 30px;
        }
        &.fyp-tip:before {
            content: '元';
            display: inline-block;
        }
        &.age-tip:before {
            content: '歲';
            display: inline-block;
        }

        &:after {
            content: attr(age-tips);
            color: $placeholder-black;
            display: block !important;
            position: absolute;
            width: 100%;
            font-size: 10px;
            top: 95%;
            right: 0%;
        }
    }

    .number {
        background-color: $primary-white;
        border: 1px solid $sum-row-grey;
    }
    .x-cancel-icon {
        position: absolute;
        top: 14px;
        right: 4%;
        background-color: white;
        cursor: pointer;
        width: 12px;
        height: 12px;
    }
    .form-label {
        font-size: 12px;
        color: $placeholder-black;
        margin-bottom: 0;
    }

    &.ins-age {
        &.error-state {
            input {
                border: 1px solid $fourth-red !important;
            }
            &:after {
                color: $fourth-red !important;
            }
        }
    }
}
:deep(.input-container .date) {
    background-color: $primary-white;
    border: 1px solid $sum-row-grey;
}
</style>
